<template>
  <div class="card">
    <div class="card-content">
      <div class="columns is-multiline">
        <div class="column is-6">
          <p class="font_18 has_lightgrey has-text-weight-semibold">
            {{ type === IS_PRODUCT ? 'Producto' : 'Servicio' }}
          </p>
        </div>
        <div class="column is-6">
          <p
            class="font_15 has-text-weight-semibold"
            :class="[
              statusProduct === IS_PENDING
                ?
                  'has_warning'
                :
                  (statusProduct === IS_APPROVED
                    ?
                      'has_success'
                    :
                      'has_danger')
            ]"
          >
            <span class="has_gray">Estatus: </span> {{ statusName }}
          </p>
        </div>

        <div class="column is-3">
          <p class="font_15 has_gray">
            Nombre del producto:
          </p>
        </div>
        <div class="column is-9">
          <p class="font_15 has_gray has-text-weight-semibold">
            {{ name }}
          </p>
        </div>

        <div class="column is-3">
          <p class="font_15 has_gray">
            Cantidad:
          </p>
        </div>
        <div class="column is-9">
          <p class="font_15 has_gray has-text-weight-semibold">
            {{ quantity }}
          </p>
        </div>
         <div class="column is-3">
          <p class="font_15 has_gray">
            Precio:
          </p>
        </div>
        <div class="column is-9">
          <p class="font_15 has_gray has-text-weight-semibold">
            {{ Intl.NumberFormat('es-MX',{style:'currency',currency:'MXN'}).format(price) }}
          </p>
        </div>
        <div class="column is-3">
          <p class="font_15 has_gray">
            Total:
          </p>
        </div>
        <div class="column is-9">
          <p class="font_15 has_gray has-text-weight-semibold">
            {{ Intl.NumberFormat('es-MX',{style:'currency',currency:'MXN'}).format(price * quantity) }}
          </p>
        </div>
        <div class="column is-3">
          <p class="font_15 has_gray">
            Portada:
          </p>
        </div>
        <div class="column is-9" v-if="image">
          <figure class="media-left">
            <img class="image" :src="`${BUCKET_NAME}${image}`" alt="PDF">
          </figure>
        </div>
        <div class="column is-9" v-else>
          <div class="file is-boxed is-justify-content-center">
            <label class="file-label">
              <input
                class="file-input"
                type="file"
                name="imageMain"
                @change="setFileMain"
                v-validate="'required|ext:jpeg,jpg,png|size:1000'"
                accept=".jpeg,.jpg,.png"
                data-vv-as="la imagen"
              >
              <span class="file-cta">
                <template v-if="previewImageMain">
                  <img :src="previewImageMain" alt="imagen">
                </template>
                <template v-else>
                  <span class="file-icon">
                    <ion-icon class="icon_upload" name="cloud-upload-outline" />
                  </span>
                  <span class="file-label has-text-centered">
                    Seleccione una imagen
                  </span>
                </template>
              </span>
            </label>
          </div>
          <div class="block has-text-centered block_btn mt-4" v-if="previewImageMain">
            <button
              class="button btn_violet"
              :class="{ 'is-loading': loading }"
              @click="uploadImage"
              :disabled="loading">
            Subir imagen
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProductInformation',
  data () {
    return {
      IS_PRODUCT: 1,
      IS_PENDING: 1,
      IS_APPROVED: 2,
      type: 1,
      name: null,
      quantity: null,
      statusProduct: 1,
      statusName: null,
      userName: null,
      phone: null,
      personTypeName: null,
      email: null,
      businessName: null,
      statusUser: false,
      legalRepresentation: null,
      image: null,
      BUCKET_NAME: process.env.VUE_APP_BUCKET_NAME,
      previewImageMain: null,
      imageFileMain: null,
      loading: false
    }
  },
  props: {
    dataDetail: {
      type: Object,
      default: () => {}
    },
    uploadImageProduct: {
      type: Function,
      default: () => {}
    }
  },
  methods: {
    spreadData () {
      this.type = this.dataDetail.product_type_id
      this.typeName = this.dataDetail.product_type_name
      this.name = this.dataDetail.product_name
      this.quantity = this.dataDetail.total_pieces
      this.price = this.dataDetail.price
      this.statusProduct = this.dataDetail.status_id
      this.statusName = this.dataDetail.status_name
      this.userName = this.dataDetail.userName
      this.isAdmin = this.dataDetail.is_admin
      this.phone = this.dataDetail.phone
      this.personTypeName = this.dataDetail.person_type_name
      this.email = this.dataDetail.email
      this.businessName = this.dataDetail.business_name
      this.statusUser = this.dataDetail.status_user
      this.legalRepresentation = this.dataDetail.legal_representative
      this.image = this.dataDetail.image
    },
    async uploadImage () {
      this.loading = true
      await this.uploadImageProduct(this.imageFileMain)
      this.loading = false
    },
    async setFileMain (event) {
      if (event) {
        const [file] = event.target.files
        // Preview
        if (file) {
          const reader = new FileReader()
          reader.readAsDataURL(file)
          reader.onload = e => {
            this.previewImageMain = e.target.result
          }
          this.imageFileMain = { key: event.target.name, file }
        }
      }
    }
  },
  beforeMount () {
    this.spreadData()
  }
}
</script>

<style lang="scss" scoped>
  .card {
    border-radius: 5px;
    box-shadow: 0 3px 45px 0 rgba(188, 188, 222, 0.27);
    background-color: #fff;
  }
  .image {
    width: 300px;
  }
</style>
